import { post } from '@/utils/request';

// 销售目标列表
const getSaleAnalysisReq = (data) => post({
    url: '/store/hierarchy.HierarchySaleTarget/getSaleAnalysis',
    data: {
        ...data,
    },
});

// 导出
const exportSaleAnalysisReq = (data) => post({
    responseType: 'blob',
    url: '/store/hierarchy.HierarchySaleTarget/exportSaleAnalysis',
    data: {
        ...data,
    },
});

export {
    getSaleAnalysisReq,
    exportSaleAnalysisReq,
}
