<template>
  <div>
    <div class="top">
      <el-tabs v-model="type">
        <el-tab-pane label="月度汇总" name="1"></el-tab-pane>
        <el-tab-pane label="售货员汇总" name="2"></el-tab-pane>
      </el-tabs>
    </div>
    <template v-if="type === '1'">
      <div class="card" style="margin-top: 1px; display: flex;">
        <div class="left">
          <el-button style="margin-left: 10px; height: 40px" type="primary" @click="handlePrint">打印</el-button>
          <el-button
            type="primary"
            plain
            @click="handleExport"
            style="height: 40px;"
            :loading="exportLoading"
            icon="el-icon-upload2"
          >导出</el-button>
        </div>
        <div class="line"></div>
        <div class="middle">
          <el-form :model="form" :inline="true">
            <el-form-item label="销售年月">
              <div style="display: flex;">
                <el-date-picker
                  v-model="form.firstMonth"
                  type="month"
                  placeholder="选择月"
                  value-format="yyyy-MM"
                ></el-date-picker>
                <div style="width: 30px; text-align: center; line-height: 40px;">至</div>
                <el-date-picker
                  v-model="form.seconedMonth"
                  type="month"
                  placeholder="选择月"
                  value-format="yyyy-MM"
                ></el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="门店">
              <el-select
                v-model="form.hierarchy_id"
                filterable
                :disabled="userInfo.hierarchy_type_id === 40"
              >
                <template v-for="item in merchantList">
                  <el-option
                    :value="item.hierarchy_id"
                    :label="item.hierarchy_name"
                    :key="item.hierarchy_id"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="right">
          <el-button type="primary" @click="handleSearch">查询</el-button>
          <el-button @click="handleReset">重置</el-button>
        </div>
      </div>
      <div class="table">
        <el-table :data="tableData.list" height="670px" v-loading="loading">
          <template v-for="item in columns">
            <el-table-column
              align="center"
              :key="item.field_alias"
              :label="item.field_text"
              :prop="item.field_alias"
            ></el-table-column>
          </template>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.total"
          :page-size="limit"
          :page-sizes="$store.state.pageSizes"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </template>
    <template v-if="type === '2'">
      <div class="card" style="margin-top: 1px; display: flex;">
        <div class="left">
          <el-button style="margin-left: 10px; height: 40px" type="primary" @click="handlePrint2">打印</el-button>
          <el-button
            type="primary"
            plain
            @click="handleExport2"
            style="height: 40px;"
            :loading="exportLoading2"
            icon="el-icon-upload2"
          >导出</el-button>
        </div>
        <div class="line"></div>
        <div class="middle">
          <el-form :model="form2" :inline="true">
            <el-form-item label="销售年月">
              <div style="display: flex;">
                <el-date-picker
                  v-model="form2.firstMonth"
                  type="month"
                  placeholder="选择月"
                  value-format="yyyy-MM"
                ></el-date-picker>
                <div style="width: 30px; text-align: center; line-height: 40px;">至</div>
                <el-date-picker
                  v-model="form2.seconedMonth"
                  type="month"
                  placeholder="选择月"
                  value-format="yyyy-MM"
                ></el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="门店">
              <el-select
                v-model="form2.hierarchy_id"
                filterable
                :disabled="userInfo.hierarchy_type_id === 40"
              >
                <template v-for="item in merchantList">
                  <el-option
                    :value="item.hierarchy_id"
                    :label="item.hierarchy_name"
                    :key="item.hierarchy_id"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="right">
          <el-button type="primary" @click="handleSearch2">查询</el-button>
          <el-button @click="handleReset2">重置</el-button>
        </div>
      </div>
      <div class="table">
        <el-table :data="tableData2.list" height="670px" v-loading="loading2">
          <template v-for="item in columns2">
            <el-table-column
              align="center"
              :key="item.field_alias"
              :label="item.field_text"
              :prop="item.field_alias"
            ></el-table-column>
          </template>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          @size-change="handleSizeChange2"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData2.total"
          :page-size="limit"
          :page-sizes="$store.state.pageSizes"
          @current-change="handleCurrentChange2"
        ></el-pagination>
      </div>
    </template>
    <LocalPrint
      :show="false"
      :printRows="printRows"
      :printData="printData"
      :title="title"
      :dialogVisible="localPrintVisible"
      @onClose="localPrintVisible = false"
    />
  </div>
</template>

<script>
import storage from "good-storage";
import { getMerchantListReq } from "@/api/goods/historyInv/index";
import {
  getSaleAnalysisReq,
  exportSaleAnalysisReq
} from "@/api/work/target/analyse.js";
import LocalPrint from "@/components/localPrint/Index.vue";

export default {
  data() {
    return {
      localPrintVisible: false,
      printRows: [],
      printData: {},
      title: "",
      page: 1,
      limit: this.$store.state.pageSizes[0],
      page2: 1,
      limit2: this.$store.state.pageSizes[0],
      tableData: {
        list: [],
        total: 0
      },
      tableData2: {
        list: [],
        total: 0
      },
      loading: false,
      exportLoading: false,
      loading2: false,
      exportLoading2: false,
      type: "1",
      form: {},
      searchForm: {}, // 搜索的form
      form2: {},
      searchForm2: {}, // 搜索的form
      merchantList: [], // 门店列表
      userInfo: {},
      columns: [
        { field_text: "序号", field_alias: "index", width: 10},
        { field_text: "目标年度", field_alias: "year", width: 15 },
        { field_text: "月份", field_alias: "month", width: 15 },
        { field_text: "门店", field_alias: "shop", width: 25 },
        {
          field_text: "月销售目标",
          field_alias: "month_amount_target",
          width: 21
        },
        { field_text: "业绩金额", field_alias: "amount", width: 21 },
        {
          field_text: "目标完成率",
          field_alias: "percent_amount",
          width: 21
        },
        {
          field_text: "月新增会员目标",
          field_alias: "month_member_target",
          width: 25
        },
        { field_text: "新增会员数", field_alias: "member", width: 21 },
        {
          field_text: "新增会员完成率",
          field_alias: "percent_member",
          width: 25
        }
      ],
      columns2: [
        { field_text: "序号", field_alias: "index", width: 10 },
        { field_text: "月份", field_alias: "year_month", width: 15 },
        { field_text: "门店", field_alias: "shop", width: 17.5 },
        { field_text: "售货员", field_alias: "store_user_name", width: 14 },
        { field_text: "月销售目标", field_alias: "month_amount_target", width: 15 },
        { field_text: "业绩金额", field_alias: "amount", width: 25 },
        { field_text: "目标完成率", field_alias: "percent_amount", width: 17.5 },
        { field_text: "月新增会员目标", field_alias: "month_member_target", width: 25 },
        { field_text: "新增会员数", field_alias: "member", width: 17.5 },
        { field_text: "新增会员完成率", field_alias: "percent_member", width: 23 },
        { field_text: "门店业绩占比", field_alias: "shop_percent", width: 20 },
        { field_text: "门店新增会员占比", field_alias: "shop_staff_percent", width: 26 }
      ]
    };
  },
  components: {
    LocalPrint
  },
  created() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.getMerchantList();
    // 获取
  },
  methods: {
    // 获取当前年
    getCurrentYear() {
      const date = new Date();
      const year = date.getFullYear();
      this.form = {
        ...this.form,
        firstMonth: `${year}-01`,
        seconedMonth: `${year}-12`,
      };
    },
    getCurrentYear2() {
      const date = new Date();
      const year = date.getFullYear();
      this.form2 = {
        ...this.form2,
        firstMonth: `${year}-01`,
        seconedMonth: `${year}-12`,
      };
    },
    // 打印
    handlePrint() {
      if (!this.tableData.list.length) {
        this.$message.warning("当前没有数据可以打印");
        return;
      }
      this.title = '销售目标分析月度汇总';
      this.printRows = this.columns;
      this.printData = this.tableData;
      this.localPrintVisible = true;
    },
    handlePrint2() {
      if (!this.tableData2.list.length) {
        this.$message.warning("当前没有数据可以打印");
        return;
      }
      this.title = '销售目标分析售货员汇总';
      this.printRows = this.columns2;
      this.printData = this.tableData2;
      this.localPrintVisible = true;
    },
    // 重置
    handleReset() {
      this.getCurrentYear();
      this.form = {
        ...this.form,
        hierarchy_id: this.merchantList[0].hierarchy_id,
      };
      this.searchForm = { ...this.form };
      this.page = 1;
      this.getList();
    },
    // 重置
    handleReset2() {
      this.getCurrentYear2();
      this.form2 = {
        ...this.form2,
        hierarchy_id: this.merchantList[0].hierarchy_id,
      };
      this.searchForm2 = { ...this.form2 };
      this.page2 = 1;
      this.getList2();
    },
    // 获取列表数据
    getList(type) {
      if (!this.searchForm.firstMonth || !this.searchForm.seconedMonth) {
        this.$message.warning("请选择销售年月");
        return;
      }
      if (!this.searchForm.hierarchy_id) {
        this.$message.warning("请选择门店");
        return;
      }
      this.loading = true;
      const data = {
        hierarchy_id: this.searchForm.hierarchy_id,
        search_date: [this.searchForm.firstMonth, this.searchForm.seconedMonth],
        type: type || this.type,
        page: this.page,
        limit: this.limit2
      };
      getSaleAnalysisReq({
        ...data
      })
        .then(res => {
          if (res.code === 1) {
            this.loading = false;
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getList2(type) {
      this.loading2 = true;
      const data = {
        hierarchy_id: this.searchForm2.hierarchy_id,
        search_date: [
          this.searchForm2.firstMonth,
          this.searchForm2.seconedMonth
        ],
        type: type || this.type,
        page: this.page2,
        limit: this.limit2
      };
      getSaleAnalysisReq({
        ...data
      })
        .then(res => {
          if (res.code === 1) {
            this.loading2 = false;
            this.tableData2 = res.data;
          }
        })
        .catch(() => {
          this.loading2 = false;
        });
    },
    // 搜索
    handleSearch() {
      if (!this.form.firstMonth || !this.form.seconedMonth) {
        this.$message.warning("请选择销售年月");
        return;
      }
      if (!this.form.hierarchy_id) {
        this.$message.warning("请选择门店");
        return;
      }
      this.searchForm = { ...this.form };
      this.getList();
    },
    handleSearch2() {
      if (!this.form2.firstMonth || !this.form2.seconedMonth) {
        this.$message.warning("请选择销售年月");
        return;
      }
      if (!this.form2.hierarchy_id) {
        this.$message.warning("请选择门店");
        return;
      }
      this.searchForm2 = { ...this.form2 };
      this.getList2();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    handleSizeChange(limit) {
      this.limit = limit;
      this.getList();
    },
    handleCurrentChange2(page) {
      this.page2 = page;
      this.getList2();
    },
    handleSizeChange2(limit) {
      this.limit2 = limit2;
      this.getList2();
    },
    handleExport() {
      if (!this.tableData.list.length) {
        this.$message.warning("当前没有数据可导出");
        return;
      }
      this.exportLoading = true;
      const data = {
        hierarchy_id: this.searchForm.hierarchy_id,
        search_date: [this.searchForm.firstMonth, this.searchForm.seconedMonth],
        type: this.type,
        function_code: "sale_target_analysis",
        export: 1
      };
      exportSaleAnalysisReq(data)
        .then(res => {
          this.exportLoading = false;
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute(
            "download",
            `销售目标分析-月度汇总表${this.searchForm.firstMonth}至${this.searchForm.seconedMonth}.xlsx`
          );
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    handleExport2() {
      if (!this.tableData2.list.length) {
        this.$message.warning("当前没有数据可导出");
        return;
      }
      this.exportLoading2 = true;
      const data = {
        hierarchy_id: this.searchForm2.hierarchy_id,
        search_date: [
          this.searchForm2.firstMonth,
          this.searchForm2.seconedMonth
        ],
        type: this.type,
        function_code: "sale_target_analysis_staff",
        export: 1
      };
      exportSaleAnalysisReq(data)
        .then(res => {
          this.exportLoading2 = false;
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute(
            "download",
            `销售目标分析-售货员汇总表${this.searchForm2.firstMonth}至${this.searchForm2.seconedMonth}.xlsx`
          );
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.exportLoading2 = false;
        });
    },
    getMerchantList() {
      getMerchantListReq().then(res => {
        if (res.code === 1) {
          this.merchantList = res.data;
          // 如果是门店
          this.form.hierarchy_id = res.data[0].hierarchy_id;
          this.form2.hierarchy_id = res.data[0].hierarchy_id;
          this.getCurrentYear();
          this.getCurrentYear2();
          this.searchForm = { ...this.form };
          this.searchForm2 = { ...this.form2 };
          // 获取列表
          this.getList('1');
          this.getList2('2');
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
/deep/ .el-form-item {
  margin-bottom: 0 !important;
}
/deep/ .el-tabs__nav-wrap::after {
  height: 0px;
}
/deep/ .el-tabs__header {
  margin: 0 !important;
  padding-left: 50px;
  background: white;
}
/deep/ .tabs {
  padding: 0 !important;
}
/deep/ .el-tabs__content {
}
/deep/ .el-tabs__item {
  font-size: 14px;
  height: 50px;
  line-height: 50px;
}
.top {
  background: white;
}
</style>
